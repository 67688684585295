<template>
  <div v-if="currentRouteName === 'Livre.Cadastro'">
    <h4 id="dropdownLegend">Quais atores que você deseja relacionar?</h4>
    <div class="dropdown">
      <input
        type="text"
        class="form-control w-100"
        name="searchName"
        placeholder="Digite aqui o nome dos atores ou organizações"
        @focus="focused = true"
        @blur="handleBlur"
        @change="changeHandler"
        @keyup="keyupHandler"
        v-model="inputText"
        autocomplete="off"
      >
      <ul class="dropdown-menu w-100" :class="{ 'show': focused && suggestionsList.length }" aria-labelledby="dropdownLegend">
        <li v-for="item of suggestionsList" :key="item.id" @click="handleSelected(item), actorSelectedEdit = item, actorSelectedEdit = {nome_do_ator: item.nome_do_ator, foto_perfil: item.foto_perfil}">
          <button type="button" class="dropdown-item">
            {{ item.id }} - {{ item.nome_do_ator }}
          </button>
        </li>
      </ul>
    </div>
    <div class="suggestions d-flex">
        <div v-for="actor of actorSelected" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1">
          <span :class="{ 'clickable' : withCallback }" @click="selectedActor(actor), showModal = true, actorSelectedEdit = {nome_do_ator: actor.nome_do_ator, foto_perfil: actor.foto_perfil}">{{ actor.nome_do_ator }}</span>
          <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor, 'atorSearch')">X</button>
        </div>
    </div>
    <button type="submit" class="col-4 mx-1 mt-4 btn btn-primary rounded-pill mw-200 darkblue" :disabled="sendingState" @click="wasValidated = true">
      <span v-if="sendingState">Registrando ... <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
      <span v-else>Finalizar cadastro</span>
    </button>
    <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModal" @close="showModal = false">
        <modal>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-bottom: 40px; padding-right: 20px;">
              <button class="btn" @click="showModal = false">
                <img src="../assets/icon-close.png" alt="">
              </button>
            </div>
          </div>
          <h3 style="padding: 0 3em;">Você gostaria de se associar a {{actorSelectedEdit.nome_do_ator}}</h3>
          <div class="container_box">
            <div class="d-flex justify-content-center">
              <img :src="actorSelectedEdit.foto_perfil" alt="" width="128" height="128" class="foto-perfil-assoc" />
            </div>
            <div class="d-flex justify-content-center">
              <p class="">{{actorSelectedEdit.nome_do_ator}}</p>
            </div>
            <div class="col-12 mb-4 mt-4">
              <label for="ligacao" class="form-label fw-bold">Qual o tipo de ligação desejada?</label>
              <div class="d-flex">
                <select id="ligacao" name="ligacao" class="form-select w-100" aria-label="Ligação desejada" v-model="tipoLigacao">
                  <option selected="selected" value="">Escolha um tipo de ligação</option>
                  <option v-for="associacao of associacoesAll" :key="associacao.id" :value="associacao.id" name="tipoLigacao">{{associacao.name}}</option>
                </select>
              </div>
            </div>
            <div class="d-flex justify-content-between pb-5 pt-3">
              <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModal = false">
                Voltar para o cadastro
              </button>
              <button class="btn btn-primary px-4 py-2 rounded-pill"  @click="handleBadgeClick(), showModal = false">
                Associar Ator
              </button>
            </div>
          </div>
        </modal>
    </div>
  </div>
    <div v-if="currentRouteName === 'Livre.Visualizacao' || currentRouteName === 'Livre.Edicao'">
      <div class="row w-100 mb-5">
        <button class="col p-2 pb-3 btn-step" :class="{ active: step === 1}" type="button" @click="tryGoTo(1)">Atores</button>
        <button class="col p-2 pb-3 btn-step" :class="{ active: step === 2}" type="button" @click="tryGoTo(2)">Parcerias</button>
        <button class="col p-2 pb-3 btn-step" :class="{ active: step === 3}" type="button" @click="tryGoTo(3)">Planos de Ação</button>
      </div>
      <div class="spin-load" v-if="loader === true">
        <div class="spinner-grow text-primary spn" role="status">
        </div>
      </div>
      <fieldset v-show="step === 1" class="row w-100" :disabled="sendingState || readOnlyMode">
        <template v-if="registry !== null">
          <div class="box_actors">
            <div v-for="actor of registry.lista_atores_tipo" :key="actor.id" :class="{ 'clickable' : withCallback }" class="container_cards">
              <div class="card">
                <div class="card-img-top align-images">
                  <div>
                    <img v-if="registry.foto_perfil !== null" :src="registry.foto_perfil" class="img_assoc" alt="">
                    <img v-if="registry.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                  <div>
                    <img v-if="actor.foto_perfil !== null" :src="actor.foto_perfil" class="img_assoc" alt="">
                    <img v-if="actor.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                </div>
                <div class="card-body">
                  {{ actor.nome_do_ator }}
                </div>
                <hr class="box_fora">
                <div class="flexing-buttons">
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleshow($event, actor, 'ator')" title="Visualizar">
                    <img src="../assets/icons/visualizar.svg" alt="Visualizar">
                  </button>
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleEdit($event, actor, 'ator')" title="Editar">
                    <img src="../assets/icons/editar.svg" alt="Editar">
                  </button>
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor, 'ator')" title="Remover">
                    <img src="../assets/icons/excluir.svg" alt="Remover">
                  </button>
                </div>
              </div>
            </div>
            <div v-for="actor of registry.lista_atores_tipo_para" :key="actor.id" :class="{ 'clickable' : withCallback }" class="container_cards">
              <div class="card">
                <div class="card-img-top align-images">
                  <div>
                    <img v-if="registry.foto_perfil !== null" :src="registry.foto_perfil" class="img_assoc" alt="">
                    <img v-if="registry.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                  <div>
                    <img v-if="actor.foto_perfil !== null" :src="actor.foto_perfil" class="img_assoc" alt="">
                    <img v-if="actor.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                </div>
                <div class="card-body">
                  {{ actor.nome_do_ator }}
                </div>
                <hr class="box_fora">
                <div class="flexing-buttons">
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleshow($event, actor, 'ator')" title="Visualizar">
                    <img src="../assets/icons/visualizar.svg" alt="Visualizar">
                  </button>
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleEdit($event, actor, 'ator')" title="Editar">
                    <img src="../assets/icons/editar.svg" alt="Editar">
                  </button>
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor, 'ator')" title="Remover">
                    <img src="../assets/icons/excluir.svg" alt="Remover">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </fieldset>
      <fieldset v-show="step === 2" class="row w-100" :disabled="sendingState || readOnlyMode">
        <template v-if="parceriasator !== null">
          <div class="box_actors">
            <div v-for="parceria of parceriasator" :key="parceria.id" :class="{ 'clickable' : withCallback }" class="container_cards">
              <div class="card">
                <div class="card-img-top align-images">
                  <div>
                    <img v-if="registry.foto_perfil !== null" :src="registry.foto_perfil" class="img_assoc" alt="">
                    <img v-if="registry.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                  <div>
                    <img src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                </div>
                <div class="card-body">
                  {{ parceria.nome_do_programa }} <br> {{parceria.nome_da_acao}}
                </div>
                <hr class="box_fora">
                <div class="flexing-buttons">
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleshow($event, parceria, 'parceria')" title="Visualizar">
                    <img src="../assets/icons/visualizar.svg" alt="Visualizar">
                  </button>
                  <!-- <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleEdit($event, parceria, 'parceria')" title="Editar">
                    <img src="../assets/icons/editar.svg" alt="Editar">
                  </button> -->
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, parceria, 'parceria')" title="Remover">
                    <img src="../assets/icons/excluir.svg" alt="Remover">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </fieldset>
      <fieldset v-show="step === 3" class="row w-100" :disabled="sendingState || readOnlyMode">
        <template v-if="planosacao !== null">
          <div class="box_actors">
            <div v-for="plano of planosacao" :key="plano.id" :class="{ 'clickable' : withCallback }" class="container_cards">
              <div class="card">
                <div class="card-img-top align-images">
                  <div>
                    <img v-if="registry.foto_perfil !== null" :src="registry.foto_perfil" class="img_assoc" alt="">
                    <img v-if="registry.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                  <div>
                    <img src="../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                  </div>
                </div>
                <div class="card-body">
                  {{ plano.proposta }} <br> {{plano.desafio}}
                </div>
                <hr class="box_fora">
                <div class="flexing-buttons">
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleshow($event, plano, 'plano')" title="Visualizar">
                    <img src="../assets/icons/visualizar.svg" alt="Visualizar">
                  </button>
                  <!-- <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleEdit($event, plano, 'plano')" title="Editar">
                    <img src="../assets/icons/editar.svg" alt="Editar">
                  </button> -->
                  <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, plano, 'plano')" title="Remover">
                    <img src="../assets/icons/excluir.svg" alt="Remover">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </fieldset>
    </div>

  <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModalVisualizar" @close="showModalVisualizar = false">
      <modal>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-bottom: 40px; padding-right: 20px;">
            <button class="btn" @click="showModalVisualizar = false">
              <img src="../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <div style="padding: 0 3em; width: 624px"></div>
        <div class="container_box">
          <div class="d-flex justify-content-center">
            <img v-if="dados.foto_perfil !== null" :src="dados.foto_perfil" alt="" class="img_assoc" />
            <img v-if="dados.foto_perfil === null" src="../assets/logo-letra-branca-fundo-azul.png" alt="" class="img_assoc" />
          </div>
          <div class="">
            <p class="">{{dados.nome_do_ator || dados.desafio || dados.nome_da_acao}}</p>
            <p>{{dados.nome_do_programa}}</p>
          </div>
          <div class="col-12 mb-4 mt-4">
            <hr>
            <div class="d-flex" style="justify-content: space-around;">
              <label for="ligacao" class="form-label fw-bold">Tipo de conexão </label> {{tipoConexao}}
            </div>
            <hr>
          </div>
          <div class="d-flex justify-content-between pb-5 pt-3">
            <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModalVisualizar = false">
              Voltar para o cadastro
            </button>
            <button class="btn btn-primary px-4 py-2 rounded-pill"  @click="handleRemove($event, actor), showModalVisualizar = false">
              Excluir
            </button>
          </div>
        </div>
      </modal>
  </div>

  <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModal" @close="showModal = false">
      <modal>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-bottom: 40px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <h3 style="padding: 0 3em;">Você gostaria de se associar a
          {{actorSelectedEdit.nome_do_ator}}
          </h3>
        <div class="container_box">
          <div class="d-flex justify-content-center">
            <img :src="actorSelectedEdit.foto_perfil" alt="" width="128" height="128" class="foto-perfil-assoc"/>
          </div>
          <div class="d-flex justify-content-center">
            <p class="">
              {{actorSelectedEdit.nome_do_ator}}
            </p>
          </div>
          <div class="col-12 mb-4 mt-4">
            <label for="ligacao" class="form-label fw-bold">Qual o tipo de ligação desejada?</label>
            <div class="d-flex">
              <select id="ligacao" name="ligacao" class="form-select w-100" aria-label="Ligação desejada" v-model="tipoLigacao">
                <option v-for="associacao of associacoesAll" :key="associacao.id" :value="associacao.id" name="tipoLigacao">{{associacao.name}}</option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-between pb-5 pt-3">
            <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModal = false">
              Voltar para o cadastro
            </button>
            <button class="btn btn-primary px-4 py-2 rounded-pill"  @click="handleBadgeClick(actor), showModal = false">
              Associar
            </button>
          </div>
        </div>
      </modal>
  </div>
<div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModalEdit" @close="showModalEdit = false">
      <modal>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-bottom: 40px; padding-right: 20px;">
            <button class="btn" @click="showModalEdit = false">
              <img src="../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <h3 style="padding: 0 3em;">Você gostaria de se associar a
          {{actorSelectedEdit.nome_do_ator}}
          </h3>
        <div class="container_box">
          <div class="d-flex justify-content-center">
            <img :src="actorSelectedEdit.foto_perfil" alt="" width="128" height="128" class="foto-perfil-assoc"/>
          </div>
          <div class="d-flex justify-content-center">
            <p class="">
              {{actorSelectedEdit.nome_do_ator}}
            </p>
          </div>
          <div class="col-12 mb-4 mt-4">
            <label for="ligacao" class="form-label fw-bold">Qual o tipo de ligação desejada?</label>
            <div class="d-flex">
              <select id="ligacao" name="ligacao" class="form-select w-100" aria-label="Ligação desejada" v-model="tipoLigacao">
                <option v-for="associacao of associacoesAll" :key="associacao.id" :value="associacao.id" name="tipoLigacao">{{associacao.name}}</option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-between pb-5 pt-3">
            <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModalEdit = false">
              Voltar para o cadastro
            </button>
            <button class="btn btn-primary px-4 py-2 rounded-pill"  @click="handleBadgeClickEdit(actor), showModalEdit = false">
              Associar
            </button>
          </div>
        </div>
      </modal>
  </div>
</template>

<script>
/*eslint-disable */
import { AssociacoesRepository, AtoresRepository, GetTipoAssociacao } from '../libs/repositories';
import { debounce } from '../libs/utils/index.js';
export default {
  name: 'ActorSearch',
  props: {
    badgeClickCallback: {
      type: Function,
      required: false,
      default: null
    },
    badgeClickCallbackRemove: {
      type: Function,
      required: false,
      default: null
    },
    badgeClickCallbackEdit: {
      type: Function,
      required: false,
      default: null
    },
    badgeClickCallbackRemoveParceria: {
      type: Function,
      required: false,
      default: null
    },
    badgeClickCallbackRemovePlano: {
      type: Function,
      required: false,
      default: null
    },
    handleBadgeClickEdit: {
      type: Function,
      required: false,
      default: null
    },
    registry: {
      type: Object,
      required: false,
      default: null
    },
    loader: {
      type: Object,
      required: false,
      default: null
    },
    parceriasator: {
      type: Object,
      required: false,
      default: null
    },
    planosacao: {
      type: Object,
      required: false,
      default: null
    },
    removerparceriasator: {
      type: Object,
      required: false,
      default: null
    }
  },
  beforeCreate: async function () {
    const result = await GetTipoAssociacao.getAll();
    this.associacoesAll = result;
  },
  data () {
    return {
      withCallback: (typeof this.badgeClickCallback === 'function' || typeof this.badgeClickCallbackRemove === 'function'),
      inputText: '',
      suggestionsList: [],
      actorSelected: [],
      /** @type {Set<number>} */
      actorIdsSelected: new Set(),
      focused: false,
      lastSearchedValue: '',
      showModal: false,
      selectedUser: null,
      teste: 'teste',
      associacoesAll: [],
      result: [],
      tipoLigacao: null,
      actor: null,
      actorRegistry: [],
      showModalVisualizar: false,
      dados: [],
      tipoConexao: '',
      sendingState: false,
      wasValidated: false,
      step: 1,
      load: true,
      actorSelectedEdit: null,
      actorLoggedEdit: this.$store.state.idUsuario,
      showModalEdit: false
    };
  },
  methods: {
    /**
     * a variável `self` irá conter uma referência ao elemento vue instanciado
     */
    manageDataRequest: debounce(async (self, nameHint) => {
      if (nameHint.length < 2) {
        self.actorIdsSelected.clear();
        self.suggestionsList = [];
        return;
      };
      if (self.lastSearchedValue === nameHint) return;

      self.lastSearchedValue = nameHint;

      const response = await AtoresRepository.searchByName({ nameHint, excludedIds: [...self.actorIdsSelected] });

      if (response.error) {
        // eslint-disable-next-line
        console.trace(response.error);
      } else {
        self.suggestionsList = response.data;
      }
    }, 520), // 50ms foi o tempo considerado seguro para o fluxo de digitação
    tryGoTo (destinationStep) {
      this.step = destinationStep;
    },
    changeHandler (event) {
      this.manageDataRequest(event.target.value);
    },
    keyupHandler (event) {
      this.manageDataRequest(event.target.value);
    },
    handleBlur () {
      window.setTimeout(() => {
        this.focused = false;
      }, 100);
    },
    handleSelected (actor) {
      if (!this.actorIdsSelected.has(actor.id)) {
        this.actorIdsSelected.add(actor.id);
        this.actorSelected = [...this.actorSelected, actor];
        this.suggestionsList = this.suggestionsList.filter((entry) => entry.id !== actor.id);
        this.$emit('actorIdsSelected', this.actorIdsSelected);
      }
    },
    handleshow ($event, actor, tipo) {
      $event.stopPropagation();
      let nomeConexao = '';
      if (tipo === 'ator') {
        this.associacoesAll.forEach(function (nome) {
          if (actor.tipo_id === nome.id) {
            nomeConexao = nome.name;
          }
        });
      } else if (tipo === 'parceria') {
        nomeConexao = 'Parceria';
      } else if (tipo === 'plano') {
        nomeConexao = 'Plano de Ação';
      }

      this.tipoConexao = nomeConexao;
      this.dados = actor;
      this.showModalVisualizar = true;
    },
    handleEdit ($event, actor, tipo) {
      $event.stopPropagation();
      this.actorSelectedEdit = actor;
      this.showModalEdit = true;
    },
    async handleRemove ($event, actor, tipo) {
      $event.stopPropagation();
      if (tipo === 'ator') {
        if (typeof this.badgeClickCallbackRemove === 'function') {
          this.badgeClickCallbackRemove((this.actor === null ? actor : this.actor), this.tipoLigacao);
        }
      } else if (tipo === 'parceria') {
        const syncReponse = await AtoresRepository.removeActorByParceria({
          atorId: this.registry.id,
          parceriaId: actor.id
        });
        if (typeof this.badgeClickCallbackRemoveParceria === 'function') {
          this.badgeClickCallbackRemoveParceria(this.registry.id, actor.id);
        }
      } else if (tipo === 'plano') {
        const syncReponse = await AtoresRepository.removeActorByPlano({
          atorId: this.registry.id,
          planoId: actor.id
        });
        if (typeof this.badgeClickCallbackRemovePlano === 'function') {
          this.badgeClickCallbackRemovePlano(this.registry.id, actor.id);
        }
      } else if (tipo === 'atorSearch') {
        if (this.actorIdsSelected.has(actor.id)) {
          this.actorIdsSelected.delete(actor.id);
          this.actorSelected = this.actorSelected.filter(entry => entry.id !== actor.id);
        }
      }
    },
    selectedActor (actor) {
      this.actor = actor;
    },
    handleBadgeClickRemove (actor) {
      if (typeof this.badgeClickCallbackRemove === 'function') {
        this.badgeClickCallbackRemove((this.actor === null ? actor : this.actor), this.tipoLigacao);
      }
    },
    handleBadgeClickEdit () {
      console.log(this.actorSelectedEdit);
      if (typeof this.badgeClickCallbackEdit === 'function') {
        this.badgeClickCallbackEdit((this.actor === null ? this.actorSelectedEdit : this.actor), this.tipoLigacao);
      }
    },
    async handleBadgeClick (actor) {
       if (typeof this.badgeClickCallback === 'function') {
        this.badgeClickCallback((this.actor === null ? actor : this.actor), this.tipoLigacao);
      }
    },
    reloadPage () {
      window.location.reload();
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name;
    }
  }
};
</script>

<style scoped>
.bg-secondary.rounded-pill {
  min-width: fit-content;
  max-width: fit-content;
  float:left;
}
.close {
  background-color: transparent;
  border: none;
  font-family: monospace;
  font-size: 12px;
  color: rgb(97, 95, 95) !important;
}
.clickable {
  cursor: pointer;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
modal {
  background-color: white;
  display: block;
  max-width: 624px;
  border-radius: 5px;
}
h3{
  font-size: 23px;
}
.container_box{
  width: 360px;
  margin: 0 auto;
}
.img_assoc{
  width:80px;
  min-height:80px;
  border-radius:100%;
}
.box_actors{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.actors{
  margin: 10px 10px;
}
.align-images{
  display: inline-flex;
}
/* .card{
  padding: 60px;
} */
.container_cards{
  display:flex;
  cursor: initial;
}
.card{
  width: 300px;
  height: 300px;
  margin-bottom: 30px;
  border: 0px;
  padding-top: 45px;
  box-shadow: 0 3px 20px rgba(189,189,206,0.36);
}
.align-images{
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(20px);
}
.align-images div+div{
  transform: translateX(-40px);
}
.align-images div img{
  border: 4px solid #fff;
}
hr.box_fora{
  margin-bottom: 0px;
}
.flexing-buttons{
  padding: 10px 0px;
}
.btn-step {
  background-color: transparent;
  color: black;
  border: none;
}
.btn-step.active {
  font-weight: bold;
}
.btn-step{
  transition: width 0.6s ease;
  border-bottom: 3px solid #fff;
}
.active {
    text-align: center;
    white-space: nowrap;
    border-bottom: 3px solid #007BFF;
    transition: width 0.6s ease;
}
.foto-perfil-assoc{
  width:128px;
  height:128px;
  border-radius: 128px;
}
</style>
