<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex vh-100">
        <div class="wrapper w-100 m-auto pb-5">
          <div class="text-center">
            <form id="formCadastroAtoresFisicos" action="" method="post" class="form-custom bg-white p-5" :class="{ 'was-validated': wasValidated}" @submit="checkForm">
              <ActorSearchLivre
              :badgeClickCallback="addSelected"
              :badgeClickCallbackRemove="removeSelected"
              :badgeClickCallbackEdit="editSelected"
              :badgeClickCallbackRemoveParceria="removeParceria"
              :badgeClickCallbackRemovePlano="removePlano"
              :registry="registry"
              :parceriasator="parceriasator"
              :planosacao="planosacao"
              :loader="loader"
              @actorIdsSelected="onActorIdsSelected" />
              <div v-if="selecioneLigacao" style="margin-top: 10px; color: red; font-size: 11px">
                É necessário definir o tipo de ligação entre os atores antes de salvar.<br>Você pode fazer isso, clicando em cima do ator selecionado.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import AtoresRepository from '../../libs/repositories/AtoresRepository.js';
import ActorSearchLivre from '../../components/ActorSearchLivre.vue';
import { AssociacoesRepository } from '../../libs/repositories/AssociacoesRepository.js';

export default {
  name: 'LivreEdicao',
  components: {
    ActorSearchLivre
  },
  data () {
    return {
      registry: null,
      parceriasator: null,
      planosacao: null,
      tipo: null,
      actorSelected: [],
      loader: true,
      selected: false,
      selecioneLigacao: false,
      wasValidated: false,
      listaUsers: null
    };
  },
  mouted () {
    const form = document.getElementById('formCadastroAtoresFisicos');
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await AtoresRepository.getById(id);

    const parceriasator = await AtoresRepository.searchActorByParceria(id);
    const planosacao = await AtoresRepository.searchActorByPlanosAcao(id);

    if (registry) {
      this.tipo = registry.tipo_do_ator;
      this.registry = registry;
      this.loader = false;
    }

    if (parceriasator) {
      this.parceriasator = parceriasator;
    }
    if (planosacao) {
      this.planosacao = planosacao;
    }
  },
  methods: {
    onActorIdsSelected () {
      this.selected = true;
    },
    async checkForm (event) {
      event.preventDefault();
      // console.log('map: ', this.actorSelected.map((actor) => actor.id));
      if (this.actorSelected.map((actor) => actor.tipo).length === 0 && this.selected === true) {
        this.wasValidated = false;
        this.selecioneLigacao = true;
        return;
      }
      const syncReponse = await AssociacoesRepository.sync({
        deAtorId: this.registry.id,
        criarId: this.actorSelected.map((actor) => actor.id),
        criarTipoId: this.actorSelected.map((actor) => actor.tipo)
      });
      window.location.reload();
    },
    addSelected (actor, tipoligacao) {
      this.actorSelected = [...this.actorSelected, Object.assign({}, actor, { tipo: parseInt(tipoligacao) })];
    },
    async removeSelected (actor) {
      this.actorSelected = [...this.actorSelected, Object.assign({}, actor, { tipo: parseInt(actor.tipo_id) })];
      
      if(actor.para_ator_id === this.registry.id){
        this.syncRemove(actor.de_ator_id, actor.para_ator_id, actor.tipo_id);
        let listaUsers = Array.from(this.registry.lista_atores_tipo_para);
        listaUsers = listaUsers.filter(atorLigacao => atorLigacao.de_ator_id !== actor.de_ator_id)
        this.registry.lista_atores_tipo_para = listaUsers;
      } 
      if(actor.de_ator_id === this.registry.id){
        this.syncRemove(actor.para_ator_id, actor.de_ator_id, actor.tipo_id);
        let listaUsers = Array.from(this.registry.lista_atores_tipo);
        listaUsers = listaUsers.filter(atorLigacao => atorLigacao.para_ator_id !== actor.para_ator_id)
        this.registry.lista_atores_tipo = listaUsers; 
      }
    },
    async syncRemove(parm1, parm2, parm3){
        let syncReponse = await AssociacoesRepository.sync({
          deAtorId: [parm1],
          desfazerId: [parm2],
          desfazerTipoId: [parm3]
        });
    },
    async syncAdd(parm1, parm2, parm3){
        let syncReponse = await AssociacoesRepository.sync({
          deAtorId: parm1,
          criarId: [parm2],
          criarTipoId: [parm3]
        });
    },
    async editSelected(actor, tipoLigacao){
      if(actor.para_ator_id === this.registry.id){
        this.syncRemove(actor.de_ator_id, actor.para_ator_id, actor.tipo_id);
        this.syncAdd(actor.de_ator_id, actor.para_ator_id, tipoLigacao );
        this.listaUsers = Array.from(this.registry.lista_atores_tipo_para);
        this.listaUsers = this.listaUsers.forEach(element => {
          if(element.para_ator_id === actor.para_ator_id){
            element.tipo_id = tipoLigacao;
            this.registry.lista_atores_tipo_para = this.listaUsers;
          }
        });
      } 
      if(actor.de_ator_id === this.registry.id){
        this.syncRemove(actor.para_ator_id, actor.de_ator_id, actor.tipo_id);
        this.syncAdd(actor.para_ator_id, actor.de_ator_id, tipoLigacao );
        this.listaUsers = Array.from(this.registry.lista_atores_tipo);
        this.listaUsers = this.listaUsers.forEach(element => {
          if(element.de_ator_id === actor.de_ator_id){
            element.tipo_id = tipoLigacao;
            this.registry.lista_atores_tipo = this.listaUsers;
          }
        });
      }
    },
    async removeParceria(actor, parceria){
      this.parceriasator = this.parceriasator.filter(element => element.id !== parceria)
    },
    async removePlano(actor, plano){
      this.planosacao = this.planosacao.filter(element => element.id !== plano)
    }
  }
};

</script>
