<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">
          <AssociacaoLivreEdicao :registry="registry" :parceriasator="parceriasator" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssociacaoLivreEdicao from './AssociacaoLivreEdicao.vue';
import AtoresRepository from '../../libs/repositories/AtoresRepository.js';

export default {
  name: 'LivreTemplate',
  data () {
    return {
      registry: null,
      parceriasator: null,
      tipo: null
    };
  },
  components: {
    AssociacaoLivreEdicao
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await AtoresRepository.getById(id);

    // const parceriasator = await AtoresRepository.searchActorByParceria(id);

    if (registry) {
      this.tipo = registry.tipo_do_ator;
      this.registry = registry;
    }
    // if (parceriasator) {
    //   this.tipo = parceriasator.tipo_do_ator;
    //   this.registry = parceriasator;
    // }
  }
};

</script>
